#camera-container {
  margin: 0;
  padding: 0;
  max-width: 90vw;
  max-height: 90vh;
  border: solid 3px red;
  border-radius: 10px;
  overflow: hidden;
}

#camera-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(5);
}

