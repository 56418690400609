#center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  margin-top: 20px;
  padding-bottom: 90px;
}

